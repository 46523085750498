<template>
    <live-scoring :match="testMatch"></live-scoring>
</template>

<script>
import LiveScoring from '@/components/Tournament/LiveScoring/LiveScoring.vue'
import Match from '@/classes/Match'
import Game from '@/classes/Game'
import Team from '@/classes/Team'

export default {
  prop: ['tournamentId', 'matchId'],
  data () {
    return {
      testMatch: new Match(this.$VBL, {
        games: [new Game(this.$VBL), new Game(this.$VBL, { number: 2 })],
        homeTeam: new Team(this.$VBL, { name: 'Home' }),
        awayTeam: new Team(this.$VBL, { name: 'Away' })
      })
    }
  },
  computed: {

  },
  components: {
    LiveScoring
  }
}
</script>

<style>

</style>
